import type VueCookieComply from 'vue-cookie-comply-with-reject'
import type { ComponentPublicInstance, Ref } from 'vue'

declare global {
  interface Window {
    dataLayer: Array<IArguments>
  }
}

declare const fbq: any

const enableGA = (): void => {
  function gtm (w: Window, d: Document, s: string, l: string, i: string): void {
    (w as any)[l] = (w as any)[l] || [];
    (w as any)[l].push({
      'gtm.start': new Date().getTime(),
      'event': 'gtm.js'
    })

    const f: Element = d.getElementsByTagName(s)[0]
    const j: HTMLScriptElement = d.createElement(s) as HTMLScriptElement
    const dl: string = l != 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    f.parentNode?.insertBefore(j, f)
  }
  gtm(window, document, 'script', 'dataLayer', 'GTM-W8VWMXH')
}

const enableGM = (): void => {
  if (document.getElementById('gmapsScript')) {
    return
  }
  const config = useRuntimeConfig()
  const scriptParentNode: Element = document.getElementsByTagName('script')[0]
  const script: HTMLScriptElement = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${config.public.googleMapsApiKey}&libraries=places`
  script.async = true
  script.id = 'gmapsScript'
  script.onload = (): void => {
    isGMScriptLoaded.value = true
  }
  scriptParentNode.parentNode?.insertBefore(script, scriptParentNode)
}

const enableFB = (): void => {
  /* eslint-disable  no-param-reassign */
  const init = (f: Window, b: Document, e: string, v: string, n?: any, t?: HTMLScriptElement, s?: HTMLElement): void => {
    if ((f as any).fbq) {
      return
    }
    n = (f as any).fbq = function (...args: any): any {
      n.callMethod
        ? n.callMethod(n, args)
        : n.queue.push(args)
    }
    if (!(f as any)._fbq) {
      (f as any)._fbq = n
    }

    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e) as HTMLScriptElement
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0] as HTMLElement
    s.parentNode?.insertBefore(t, s)
    /* eslint-enable  no-param-reassign */
  }
  init(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js')
  fbq('init', '789717065591491')
  fbq('track', 'PageView')
}

const cookieConsent: Ref<ComponentPublicInstance<typeof VueCookieComply> | null> = ref(null)
const acceptedServices: Ref<Array<string>> = ref([])

const isGMScriptLoaded = ref<boolean>(false)

export {
  enableGA,
  enableGM,
  enableFB,
  cookieConsent,
  acceptedServices,
  isGMScriptLoaded
}
